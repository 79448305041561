import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import './PortfolioDetail.css';

function PortfolioDetail({ item }) {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="card no-border mx-auto">
                <div className="card-body">
                {item.contentType === "image" ? (
                        <a href={`portfolio/${item.id}`}>
                            <img className="img-thumbnail" src={`/images/portfolio/${item.id}/${item.cover}`} alt="" />
                        </a>
                    ) : (
                        <button className="button-link" onClick={() => setOpen(true)}><img className="img-thumbnail" src={`/images/portfolio/${item.id}/${item.cover}`} alt="" /></button>
                    )}
                    <div className="justify-content-center">
                            <div className="margin-block">
                                <strong>Editorial:</strong> {item.editorial}
                                {item.contentType === "image" ? (
                                    <a href={`${item.url}`}><FontAwesomeIcon icon={faExternalLink} className='ml-2' /></a>
                                ) : (
                                    <React.Fragment>
                                        <button className="button-link" onClick={() => setOpen(true)}><FontAwesomeIcon icon={faPlay} className='ml-2' /></button>
                                        <ModalVideo
                                            youtube={{ autoplay: 1, mute: 1 }}
                                            isOpen={isOpen}
                                            videoId={`${item.videoId}`}
                                            onClose={() => setOpen(false)}
                                        />
                                    </React.Fragment>
                                )}
                                <br />
                                {(item.series !== null && item.series !== undefined) && <><strong>Serie:</strong> {item.series}<br /></>}
                                <strong>País:</strong> {item.country}<br />
                                {item.comments}
                            </div>
                        </div>  
                </div>
            </div>
        </div>
    );
}

export default PortfolioDetail;

