import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHands, faChalkboardTeacher, faLaptop, faPenNib } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import CookieConsent from 'react-cookie-consent';
import Footer from './Footer';
import PageNavbar from './PageNavbar';
import Portfolio from './Portfolio';
import ContactForm from './ContactForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';

function HomePage() {
  const { hash } = useLocation();

  useEffect(() => {
    // Wait for the browser to finish rendering before scrolling.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }, [hash]);

  return (
    <div className="App">
      <PageNavbar />
      <header className="hero bg-primary text-white text-center d-flex align-items-center justify-content-center" style={{height: '100vh'}}>
        {/* 
          Creditos del imagen: Jonathan Borba https://unsplash.com/@jonathanborba
          https://unsplash.com/photos/Z1Oyw2snqn8?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink
        */}
        <div className="overlay">
          <h1>Nos une la pasión por la ciencia,<br/>la palabra y la educación.</h1>
        </div>
      </header>
      <section id="services" className="section-block">
        <div className="container">
            <div className="section-header">
              <h2 className="section-title text-center aga-color">Servicios</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="media service-box">
                  <div className="pull-left">
                  <FontAwesomeIcon icon={faPenNib} className="block-icon" />
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading aga-color">Producción y edición</h4>
                    <ul className="media-heading-item">
                      <li>Gestión integral de proyectos editoriales educativos: idea, elaboración de contenidos y producción.</li>
                      <li>Edición de textos de Ciencias Sociales escolares y de divulgación.</li>
                      <li>Procesamiento didáctico de contenidos.</li>
                      <li>Seguimiento editorial.</li>
                    </ul>
                  </div>
                </div>
              </div>
                <div className="col-md-4">
                  <div className="media service-box">
                    <div className="pull-left">
                    <FontAwesomeIcon icon={faLaptop} className="block-icon" />
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading aga-color">Contenidos digitales</h4>
                      <ul className="media-heading-item">
                        <li>Creación y edición de contenidos para plataformas didácticas.</li>
                        <li>Ideas creativas para el uso de los recursos didácticos relacionados con las tecnologías de la información y de la comunicación (TICs).</li>
                        <li>Investigación y elaboración de contenidos y guiones para medios de comunicación.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="media service-box">
                    <div className="pull-left">
                      <FontAwesomeIcon icon={faChalkboardTeacher} className="block-icon" />
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading aga-color">Talleres</h4>
                      <p>Dictado de talleres sobre distintas temáticas relacionadas con nuestro trabajo: recursos didácticos en las Ciencias Sociales; uso de fuentes históricas en distintos niveles educativos; uso creativo de los libros de texto, entre otros temas.</p>
                    </div>
                  </div>
                </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="media service-box">
                  <div className="pull-left">
                  <FontAwesomeIcon icon={faHands} className="block-icon" />
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading aga-color">¿Con qué contamos?</h4>
                    <ul className="media-heading-item">
                      <li>Capacidad para coordinar proyectos editoriales. Amplia experiencia docente en todos los niveles educativos.</li>
                      <li>Dominio de los diseños curriculares de Ciencias Sociales.</li>
                      <li>Manejo de aplicativos informáticos generales y específicos para el trabajo editorial: Microsoft Office, Adobe InCopy, Adobe Acrobat Professional y Adobe Photoshop.</li>
                      <li>Producción en inglés y francés tanto oral como escrito.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section id="portfolio" className="section-block">
        <Portfolio />
      </section>
      <section id="meet-team" className="section-block">
        <div className="container">
          <div className="section-header">
            <hr />
            <h2 className="section-title text-center aga-color">Equipo</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-5">
              <div className="team-member">
                <div className="team-img">
                  <img className="rounded mx-auto d-block img-fluid" src="/images/01.jpg" alt="" />
                </div>
                <div className="team-info team-member-title"> 
                  <h4>Guillermina Orduna</h4>
                  <span>Editora y gerenciadora de proyectos</span>
                  <a href="https://www.linkedin.com/in/guillermina-orduna-ab280067/?originalSubdomain=ar" target="_blank" rel="noreferrer" className="team-info-href"><FontAwesomeIcon icon={faLinkedin} className="fa-lg ml-2" /></a>
                </div>
                <p><strong>Profesora de Historia, Facultad de Filosofía y Letras, Universidad de Buenos Aires.</strong><br /></p>
                <p>Desde 2005 y hasta la actualidad trabajo como coordinadora, autora y editora del área de Ciencias Sociales para distintas editoriales en Argentina y América latina.<br />También trabajé como docente de Historia en distintos establecimientos públicos y privados de la Ciudad de Buenos Aires y en la Universidad Nacional de la Matanza. Además dicto talleres sobre didáctica de la Historia.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <div className="team-member">
                <div className="team-img">
                  <img className="rounded mx-auto d-block img-fluid" src="/images/02.jpg" alt="" />
                </div>
                <div className="team-info team-member-title">
                  <h4>Natalia Daltoe</h4>
                  <span>Editora</span>
                  <a href="https://www.linkedin.com/in/natalia-daltoe-0306878b/" target="_blank" rel="noreferrer" className="team-info-href"><FontAwesomeIcon icon={faLinkedin} className="fa-lg ml-2" /></a>
                </div>
                <p><strong>Editora y redactora, Instituto Superior de Letras Eduardo Mallea.</strong><br /></p>
                <p>Genero y edito contenidos educativos en el área de Ciencias Sociales para manuales escolares.<br />Asimismo, elaboro material didáctico para portales educativos <em>on line</em> en las plataformas Edcite y Schoology. Desde hace más de quince años, escribo guiones para contenidos audiovisuales corporativos e institucionales en empresas y organismos públicos.</p>
              </div>
            </div>
          </div>
        </div>  
      </section>
      <section id="contact" className="section-block blue">
        <div className="container">
          <div className="section-header">
            <hr />
            <h2 className="section-title text-center aga-color">Contacto</h2>
          </div>
          <div className="row">
            <div className="col-md-4 contact-bottom-margin">
              <div className="card">
                <div className="card-header">
                  <h2>Contacto</h2>
                </div>
                <div className="card-body">
                  <address>
                    <p><strong>Agapanto - Servicios editoriales</strong></p>
                    Avenue de Béthusy, 64<br />
                    1012, Lausanne<br />
                    Suiza<br />
                    <strong>WhatsApp:</strong> +41 787 240 542
                  </address>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <p>Enviános un mesaje para contactarte.</p>
              <ContactForm />
            </div>
          </div>  
        </div>
      </section>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Entiendo"
        cookieName="consent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#fff", background: "#4F5AFF", fontSize: "15px", padding: "20px 20px 20px 20px" }}
        expires={999}
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}>
        <div className="py-3 px-5">
          <span>Tenga en cuenta que en nuestro sitio web utilizamos las cookies necesarias para el funcionamiento de nuestro sitio web, cookies que optimizan el rendimiento y las cookies requeridas por las herramientas de análisis de tráfico de terceros.</span>
          <br /><a href="https://www.allaboutcookies.org/cookies/" target="_blank" rel="noreferrer" className="text-color-coral">Saber más</a>
        </div>
      </CookieConsent>
    </div>
  );
}

export default HomePage;
