import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';

function Footer() {
  const year = new Date().getFullYear();
  
  return (
    <footer className='d-flex align-items-center justify-content-center bg-dark text-white footer-margin'>
      <div>
        &copy; {year} Agapanto - Servicios editoriales
        <br />
        <span className='photo-credits'>Main photo by <a href="https://unsplash.com/@jonathanborba?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className='photo-credits'>Jonathan Borba</a> on <a href="https://unsplash.com/photos/Z1Oyw2snqn8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className='photo-credits'>Unsplash</a></span>
      </div>
    </footer>
  );
}

export default Footer;
