import React from 'react';

const LogoContainer = () => {
  return (
    <div id="logo_container" className="aga-color">
      <div id="logo_image">
        <img src="/images/logo.png" alt="Agapanto Logo" width="60px" height="60px" />
      </div>
      <div id="logo_text">
        <div id="logo_main_text">AGAPANTO</div>
        <div id="logo_sec_text">Servicios editoriales</div>
      </div>
    </div>
  );
};

export default LogoContainer;
