import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';
import LogoContainer from './LogoContainer';
import './PageNavbar.css';

function PageNavbar() {
  const [expanded, setExpanded] = useState(false);

  const closeMenu = () => {
    setExpanded(false);
  };


  return (
    <Navbar bg="light" expand="lg" sticky="top" expanded={expanded}>
      <Navbar.Brand>
        <Link 
            to="page-top" 
            spy={true} 
            smooth={true} 
            offset={-70} 
            duration={350} 
            className="navbar-brand cursor-link nav-link"
            activeClass="active"
        >
            <LogoContainer />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
            <Link 
              to="services" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={350} 
              className="nav-link ml-md-2 ml-0 aga-color cursor-link"
              activeClass="active"
              onClick={closeMenu}
            >
              Servicios
          </Link>             
            <Link 
              to="portfolio" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={350} 
              className="nav-link ml-md-2 ml-0 aga-color cursor-link"
              activeClass="active"
              onClick={closeMenu}
            >
              Portfolio
          </Link>             
            <Link 
                to="meet-team" 
                spy={true} 
                smooth={true} 
                offset={-70} 
                duration={350} 
                className="nav-link ml-md-2 ml-0 aga-color cursor-link"
                activeClass="active"
                onClick={closeMenu}
              >
              Equipo
          </Link>
            <Link 
              to="contact" 
              spy={true} 
              smooth={true} 
              offset={-70} 
              duration={350} 
              className="nav-link ml-md-2 ml-0 aga-color cursor-link"
              activeClass="active"
              onClick={closeMenu}
            >
              Contacto
            </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
)};

export default PageNavbar;
