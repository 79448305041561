import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import PortfolioPage  from './PortfolioPage';
import NotFoundPage from './NotFoundPage';
import PortfolioContext from './PortfolioContext';

function App() {
  const [portfolio, setPortfolio] = useState([])

  useEffect(() => {
      fetch('/portfolio-data.json')
      .then(response => {
          if (!response.ok) {
              throw new Error("HTTP error " + response.status);
          }
          return response.json();
      })
      .then(json => {
        setPortfolio(json.portfolio);
      })
      .catch(function() {
          console.log("Fetch error");
      });
  }, []);


  return (
    <PortfolioContext.Provider value={portfolio}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/portfolio/:id" element={<PortfolioPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </PortfolioContext.Provider>
  );
}

export default App;
