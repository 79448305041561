import React, { useContext } from 'react';
import './Portfolio.css';
import PortfolioDetail from './PortfolioDetail';
import PortfolioContext from './PortfolioContext';

function Portfolio() {
    const portfolio = useContext(PortfolioContext);
    
    return (
        <div className="container">
            <div className="section-header">
                <hr />
                <h2 className="section-title text-center aga-color">Portfolio</h2>
            </div>
            <div className="row justify-content-center">
                {portfolio.map(item => (
                    <PortfolioDetail key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
}

export default Portfolio;
