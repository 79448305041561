import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PortfolioContext from './PortfolioContext';
import ImageGallery from 'react-image-gallery';
import LogoContainer from './LogoContainer';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import './PortfolioPage.css';

function findBookInPortfolio(id, portfolio) {
  if (!id || !portfolio) {
    // Ensures that the portfolio and id are properly loaded
    return null;
  }

  const book = portfolio.find(p => p.id === id && p.contentType === "image");
  
  if (!book) {
    // Ensures that the book is found in the portfolio
    return null;
  }

  // If all checks pass, return the book
  return book;
}


function PortfolioPage() {
  let { id } = useParams();
  const portfolio = useContext(PortfolioContext);  
  const prefixUrl = '/images/portfolio/' + id + '/';
  
  const book = findBookInPortfolio(id, portfolio);
  if (!book) {
    return null; // Ensures that the book is properly loaded
  }
  
  console.log(`Book found for id ${id}`);
  
  const images = book.collection.map(item => ({
    original: prefixUrl + item.image
  }));

  return (
    <div className="App">
      <Navbar bg="light" expand="lg" sticky="top">
        <Navbar.Brand>
          <a href="/" className='noStyle'>
            <LogoContainer />
          </a>
        </Navbar.Brand>
        <Nav className="ml-auto">
          <a href="/#portfolio" className='noStyle av-link ml-md-2 ml-0 aga-color cursor-link mr-4'>
            <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />Portfolio
          </a>
        </Nav>
      </Navbar>
      <div className="row m-0">
        <div className="col-12 p-0">
          <ImageGallery 
              items={images}
              showThumbnails={false}
              showPlayButton={false}
              infinite={false}
            />
        </div>
      </div>
    </div>
  );
}

export default PortfolioPage;
