import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function ContactForm() {
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: 'Saber más sobre nosostros',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this line
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const handleInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
  
    // Name validation: Allow only letters, white spaces, "." and "/"
    const nameRegEx = /^[a-zA-Z\s./]+$/;
    if (!form.name) newErrors.name = 'El nombre es requerido';
    else if (!nameRegEx.test(form.name)) newErrors.name = 'El nombre es inválido. Debe contener solo letras, espacios en blanco, "." y "/"';
  
    // Email validation: Check for common email format
    // The new RegEx allows emails without a "." before the "@" sign
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!form.email) newErrors.email = 'El correo electrónico es requerido';
    else if (!emailRegEx.test(form.email)) newErrors.email = 'El correo electrónico es inválido. Por favor, introduce una dirección de correo electrónico válida';
  
    // Subject validation: Check if a subject is selected
    if (!form.subject) newErrors.subject = 'El asunto es requerido';
  
    // Message validation: Check if message is at least 10 characters
    if (!form.message) newErrors.message = 'El mensaje es requerido';
    else if (form.message.length < 10) newErrors.message = 'El mensaje es muy corto. Por favor, introduce al menos 10 caracteres';
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
        setIsSubmitting(true);

        const formData = new FormData(e.target);

        // MdC: Introduce a 2-second delay to avoid "fast flickering" on the form submit
        await new Promise(resolve => setTimeout(resolve, 2000));

        const response = await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        });

        if (response.ok) {
            // The form was successfully submitted, clear the form
            setForm({
            name: '',
            email: '',
            subject: 'Saber más sobre nosostros',
            message: '',
            });
            setIsSubmitted(true);
        } else {
            // Handle error
            console.error("Error submitting form");
        }

        setIsSubmitting(false);
    }
  };
  
  
  return (
    <div className="position-relative">
        {isSubmitting && (
            <div style={{
                background: 'rgba(0,0,0,0.4)',
                position: 'fixed', // Change this from absolute to fixed
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999 // Ensure the overlay appears above all other content
            }}>
                <BeatLoader color={"#123abc"} loading={true} css={override} size={15} />
            </div>
        )}
        <form id="main-contact-form-v1" name="main-contact-form-v1" data-netlify="true" onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="main-contact-form-v1" />
            <div className="form-group">
                <label>Nombre</label>
                <input
                type="text"
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                name="name"
                aria-describedby="Nombre"
                placeholder="Tu nombre"
                value={form.name}
                onChange={handleInputChange}
                disabled={isSubmitting}
                readOnly={isSubmitted}
                />
                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>
            <div className="form-group">
                <label>Correo electrónico</label>
                <input
                type="text"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                name="email"
                aria-describedby="emailHelp"
                placeholder="Tu e-mail"
                value={form.email}
                onChange={handleInputChange}
                disabled={isSubmitting}
                readOnly={isSubmitted}
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
            <div className="form-group">
                <label>Asunto</label>
                <select
                className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
                name="subject"
                value={form.subject}
                onChange={handleInputChange}
                disabled={isSubmitting}
                readOnly={isSubmitted}
                >
                <option>Saber más sobre nosostros</option>
                <option>Organizar un taller</option>
                <option>Pedir un presupuesto</option>
                <option>Otro asunto</option>
                </select>
                {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
            </div>
            <div className="form-group">
                <label>Mensaje o cuestión</label>
                <textarea
                name="message"
                className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                rows="4"
                placeholder="Escribí tu mensaje aquí"
                value={form.message}
                onChange={handleInputChange}
                disabled={isSubmitting}
                readOnly={isSubmitted}
                />
                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
            </div>
            {!isSubmitted ? (
                <button id="send_mail_button" type="submit" className="btn btn-success my-2" disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />Enviar
                </button>
            ) : (
                <p style={{fontWeight: 'bold', color: 'green'}}>¡Gracias por enviar su mensaje!</p>
            )}
        </form>
    </div>
  );
}

export default ContactForm;
